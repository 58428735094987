let map = L.map('map').setView([ 64.565751, 40.628380 ], 12);

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}).addTo(map);

let m1 = L.marker([64.590770, 40.700456], {'title': 'Архангельск, улица Авиационная, д. 19'}).addTo(map);
let m2 = L.marker([64.524256, 40.612385], {'title': 'Архангельск, Ленинградский, д. 109'}).addTo(map);
let m3 = L.marker([64.550718, 40.573087], {'title': 'Павильон около ЖД вокзала'}).addTo(map);
let m4 = L.marker([64.553744, 40.551887], {'title': 'Архангельск, ул. Нагорная, д. 1'}).addTo(map);
let m5 = L.marker([64.538121, 40.562392], {'title': 'Архангельск, ул.Шабалина, д. 4'}).addTo(map);

m1.bindPopup('<div id="content">'+
                '<div id="siteNotice">'+'</div>'+
                '<h1 id="firstHeading" class="firstHeading">Адрес: г. Архангельск, улица Авиационная, д. 19.</h1>'+
                '<p id="adr">Режим работы: (по Мск. времени)</p> '+
                '<p id="adr">(пн.-пт. – с 8:00 до 18:00; с 12:00 до 13:00 обеденный перерыв)</p> '+
                '<p id="adr">(сб. – с 8:00 до 14:00)</p> '+
                '<div id="bodyContent">'+
                    '<h3>Многоканальный федеральный номер:</h3>'+
                    '<p>8 800 222 50 15 ( звонок по России бесплатный )</p> '+
                    '<p>E-mail: paxus@mail.ru</p> '+
                '</div>'+
            '</div>');

m2.bindPopup('<div id="content">'+
                '<div id="siteNotice">'+'</div>'+
                '<h1 id="firstHeading" class="firstHeading">Адрес:</h1>'+
                '<p id="adr">Архангельск, Ленинградский 109</p> '+
                '<div id="bodyContent">'+
                    '<h3>Часы работы:</h3>'+
                    '<p>9:00 - 18:00</p> '+
                '</div>'+
            '</div>');
m3.bindPopup('<div id="content">'+
                '<div id="siteNotice">'+'</div>'+
                '<h1 id="firstHeading" class="firstHeading">Адрес:</h1>'+
                '<p id="adr">Павильон около ЖД вокзала</p> '+
                '<div id="bodyContent">'+
                    '<h3>Часы работы:</h3>'+
                    '<p>9:00 - 18:00</p> '+
                '</div>'+
            '</div>');
m4.bindPopup('<div id="content">'+
                '<div id="siteNotice">'+'</div>'+
                '<h1 id="firstHeading" class="firstHeading">Адрес:</h1>'+
                '<p id="adr">Архангельск, ул. Нагорная, 1</p> '+
                '<div id="bodyContent">'+
                    '<h3>Часы работы:</h3>'+
                    '<p>9:00 - 18:00</p> '+
                '</div>'+
            '</div>');
m5.bindPopup('<div id="content">'+
                '<div id="siteNotice">'+'</div>'+
                '<h1 id="firstHeading" class="firstHeading">Адрес:</h1>'+
                '<p id="adr">Архангельск, ул.Шабалина д.4</p> '+
                '<div id="bodyContent">'+
                    '<h3>Часы работы:</h3>'+
                    '<p>9:00 - 18:00</p> '+
                '</div>'+
            '</div>');
